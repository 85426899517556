import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const navigate = useNavigate();
  const location = useLocation(); // Obtém a rota atual
  const [isTokenValid, setIsTokenValid] = useState(true); // Estado para armazenar a validade do token

  useEffect(() => {
    // Verifica a rota atual
    if (location.pathname === '/admin') {
      // Função para verificar se o token expirou e se a variável acesso é "admin"
      const checkTokenExpiration = () => {
        const token = sessionStorage.getItem('authToken');
        if (!token) {
          setIsTokenValid(false); // Token não encontrado, usuário será redirecionado
          navigate("/login"); // Redireciona para a página de login
          return;
        }

        // Decodificando o token para verificar a expiração e a variável "acesso"
        const tokenPayload = JSON.parse(atob(token.split('.')[1])); // Decodificando a parte do payload
        const currentTime = Math.floor(Date.now() / 1000); // Tempo atual em segundos

        // Verificando se o token expirou
        if (tokenPayload.exp < currentTime) {
          setIsTokenValid(false); // Token expirado
          sessionStorage.removeItem('authToken'); // Remove o token expirado
          navigate("/login"); // Redireciona para a página de login
          return;
        }

        // Verificando se o usuário tem acesso "admin"
        if (tokenPayload.acesso !== "admin") {
          setIsTokenValid(false); // Acesso negado, não é "admin"
          navigate("/unauthorized"); // Redireciona para uma página de acesso negado (crie uma página para isso)
          return;
        }
      };

      // Verificando a validade do token para a rota /admin
      checkTokenExpiration();
    }
  }, [location.pathname, navigate]); // Adiciona location.pathname para monitorar mudanças na rota

  // Se o token for inválido, o componente protegido não será renderizado
  if (!isTokenValid) {
    return null; // Não renderiza o componente protegido
  }

  return children; // Renderiza o componente protegido
}

export default ProtectedRoute;
