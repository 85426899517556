import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute2 = ({ children }: { children: React.ReactNode }) => {
  const [notaFinal, setNotaFinal] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotaFinal = async () => {
      try {
        const token = sessionStorage.getItem("authToken");
        if (!token) {
          console.error("Token não encontrado");
          return;
        }

        const userData = JSON.parse(sessionStorage.getItem("user") || "");

        const response = await axios.get(
          `https://api-protmar-educacional.vercel.app/getnotaprovafinal/${userData.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setNotaFinal(response.data[0].notaObtida); // Supondo que a resposta contenha a nota
      } catch (error) {
        console.error("Erro ao buscar a nota final", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotaFinal();
  }, []);

  // Exibir um carregamento enquanto a nota não é recuperada
  if (loading) {
    return <div>Carregando...</div>;
  }

  // Verificar se a nota final é maior ou igual a 7
  if (notaFinal !== null && notaFinal >= 7) {
    return <>{children}</>; // Permitir acesso ao conteúdo protegido
  }

  // Redirecionar para outra página (ex: página de erro ou login) caso a nota seja menor que 7
  return <Navigate to="/erro" />;
};

export default ProtectedRoute2;
