import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/appComponent/App';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { IntegraçãoComponent } from './components/integracaoComponent/IntegracaoCompoenent';
import ProtectedRoute from './ProtectedRoute';
import { AdminPage } from './components/adminPage/AdminPage';
import { InitialPage } from './components/initialPage/InitialPage';
import { VideoPage } from './components/videoPage/VideoPage';
import { QuestionsPage } from './components/questionsPage/QuestionsPage';
import App2 from './components/certificadoPage/CertificadoPage';
import { Navigate } from 'react-router-dom';
import ProtectedRoute2 from './ProtectedRoute2';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='/' element={<Navigate to="/login" replace />} />

        {/* Rota pública para login */}
        <Route path="/login" element={<App />} />

        {/* Rota protegida para integração */}
        <Route
          path="/integracao"
          element={
            <ProtectedRoute>
              <IntegraçãoComponent />
            </ProtectedRoute>
          }
        />

        {/* Rota protegida com parâmetro na URL */}
        <Route
          path="/integracao/videos/:id"
          element={
            <ProtectedRoute>
              <VideoPage />
            </ProtectedRoute>
          }
        />

        <Route path='/admin' element={
          <ProtectedRoute>
            <AdminPage />
          </ProtectedRoute>
        } />

        <Route
          path="/admin/questoes/:id"
          element={
            <ProtectedRoute>
              <QuestionsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/certificado"
          element={
            <ProtectedRoute2>
              <App2 />
            </ProtectedRoute2>
          }
        />
      </Routes>
    </Router>
  </React.StrictMode>
);
