import React, { useRef, useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import { Menu } from '../menuCompont/Menu';
import 'bootstrap/dist/css/bootstrap.min.css';
import './certificadoPage.css';
import jsPDF from 'jspdf';

const App2: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const [nomeAluno, setNomeAluno] = useState('');
  const [cpfAluno, setCpfAluno] = useState('');
  const [rgAluno, setRgAluno] = useState('');
  const [nascimentoAluno, setNascimentoAluno] = useState('');
  const [nomeMaeAluno, setNomeMaeAluno] = useState('');
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);  // Estado para o PDF

  const startDrawing = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.beginPath();
    ctx.moveTo(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
    setIsDrawing(true);
  };

  const draw = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.lineTo(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
    ctx.stroke();
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  const saveCanvasAsPNG = () => {
    const canvas = canvasRef.current;
    if (!canvas) {
      alert('Nenhum desenho encontrado para salvar!');
      return;
    }
  
    // Obter o Data URL do canvas com o formato PNG
    const dataUrl = canvas.toDataURL('image/png');
  
    // Criar um link para download
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = 'assinatura.png'; // Nome do arquivo salvo
    link.click();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const generateCertificado = async () => {
    try {
      const url = '/modeloCertificadoCC.pdf'; // Caminho do PDF base
      const response = await fetch(url);
      if (!response.ok) {
        console.error('Erro ao carregar o PDF:', response.statusText);
        return;
      }
  
      const existingPdfBytes = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
  
      // Obter data atual formatada
      const today = new Date();
      const day = today.getDate();
      const month = today.toLocaleString('default', { month: 'long' });
      const year = today.getFullYear();
  
      const userData = JSON.parse(sessionStorage.getItem("user") || "");
      console.log(userData)
      
      // Texto do certificado
      const text = `O Controle de Terceiros confere o presente certificado ao Sr. (a) ${userData.name}, CPF: ${cpfAluno}, RG: ${rgAluno}, Nascimento: ${nascimentoAluno}, Mãe: ${nomeMaeAluno}, por seu aproveitamento no treinamento básico de integração, realizado em Maringá - PR, ${day} de ${month} de ${year}.`;
  
      // Configuração do texto (quebra de linha automática)
      const fontSize = 12;
      const pageWidth = firstPage.getWidth();
      const pageHeight = firstPage.getHeight();
  
      const maxWidth = pageWidth - 100; // Margem de 100px
      const lines = breakTextIntoLines(text, fontSize, maxWidth);
  
      let yPosition = 300; // Posição inicial do texto
      for (const line of lines) {
        const textWidth = fontSize * 0.6 * line.length;
        const xPosition = (pageWidth - textWidth) / 2 + 80;
        firstPage.drawText(line, {
          x: xPosition,
          y: yPosition,
          size: fontSize,
        });
        yPosition -= fontSize * 1.5;
      }
  
      // Adicionar a assinatura
      const canvas = canvasRef.current;
      if (canvas) {
        const signatureDataUrl = canvas.toDataURL('image/png'); // Exportar assinatura como PNG
        const signatureImageBytes = await fetch(signatureDataUrl).then((res) => res.arrayBuffer());
        const signatureImage = await pdfDoc.embedPng(signatureImageBytes);
  
        const signatureWidth = 150; // Largura da assinatura
        const signatureHeight = (signatureWidth * signatureImage.height) / signatureImage.width;
  
        // Posição da assinatura no PDF
        const signatureX = 100; // Ajuste horizontal
        const signatureY = 100; // Ajuste vertical
  
        firstPage.drawImage(signatureImage, {
          x: signatureX,
          y: signatureY,
          width: signatureWidth,
          height: signatureHeight,
        });
      }
  
      // Salvar o PDF gerado
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const objectUrl = URL.createObjectURL(blob);
      setPdfUrl(objectUrl);
    } catch (error) {
      console.error('Erro ao gerar o certificado:', error);
    }
  };
  
  
  // Função para dividir o texto em linhas
  const breakTextIntoLines = (text: string, fontSize: number, maxWidth: number) => {
    const words = text.split(' ');
    let currentLine = '';
    const lines: string[] = [];
  
    for (const word of words) {
      const testLine = currentLine + (currentLine ? ' ' : '') + word;
      const testLineWidth = fontSize * 0.6 * testLine.length; // Estimativa simples de largura
  
      if (testLineWidth <= maxWidth) {
        currentLine = testLine;
      } else {
        if (currentLine) lines.push(currentLine);
        currentLine = word; // Começa uma nova linha
      }
    }
  
    if (currentLine) lines.push(currentLine); // Adiciona a última linha
    return lines;
  };
  
  
  

  return (
    <div className="container mt-4">
      <Menu />
      <div className="card p-4 shadow-sm">
        <h1 className="mb-4">Geração de Certificado</h1>

        <div className="mb-3">
          <label className="form-label">CPF</label>
          <input
            onChange={(e: any) => setCpfAluno(e.target.value)}
            type="text"
            className="form-control"
          />
        </div>

        <div className="mb-3">
          <label className="form-label">RG</label>
          <input
            onChange={(e: any) => setRgAluno(e.target.value)}
            type="text"
            className="form-control"
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Data Nascimento</label>
          <input
            onChange={(e: any) => setNascimentoAluno(e.target.value)}
            type="text"
            className="form-control"
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Nome da Mãe do Aluno</label>
          <input
            onChange={(e: any) => setNomeMaeAluno(e.target.value)}
            type="text"
            className="form-control"
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Assinatura do aluno</label>
          <canvas
            ref={canvasRef}
            width={600}
            height={400}
            style={{
              border: '1px solid #000',
              cursor: 'crosshair',
              display: 'block',
              margin: '0 auto',
            }}
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={stopDrawing}
            onMouseLeave={stopDrawing}
          />
          <div className="mt-3 d-flex justify-content-start">
            <button className="btn btn-secondary ms-2" onClick={clearCanvas}>
              Limpar Canvas
            </button>
            <button className="btn btn-primary ms-2" onClick={generateCertificado}>
              Gerar Certificado
            </button>
          </div>
        </div>

        {/* Exibe o PDF gerado na tela */}
        {pdfUrl && (
          <div className="mt-4">
            <h3>Certificado Gerado</h3>
            <iframe
              src={pdfUrl}
              width="100%"
              height="500px"
              title="Certificado"
            ></iframe>
            <a href={pdfUrl} download="certificado.pdf" className="btn btn-primary mt-2">
              Baixar Certificado
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default App2;
