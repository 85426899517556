import React, { useEffect, useState } from "react";
import { Menu } from "../menuCompont/Menu";
import "./adminPage.css";
import axios from "axios";
import { Link } from "react-router-dom";

interface Video {
  _id: string;
  classVideo: string;
  name: string;
  url: string;
}

export function AdminPage() {
  const [videos, setVideos] = useState<Video[]>([]);
  const [newVideo, setNewVideo] = useState({
    url: "",
    name: "",
    classVideo: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const token = sessionStorage.getItem("authToken"); // Obtenha o token do sessionStorage
        if (!token) {
          console.error("Token não encontrado.");
          return;
        }

        const response = await axios.get("https://api-protmar-educacional.vercel.app/getvideosurl/integracao", {
          headers: {
            Authorization: `Bearer ${token}`, // Passando o token no cabeçalho
          },
        });

        if (response.data.dataVideos) {
          setVideos(response.data.dataVideos);
        } else {
          console.error("Nenhum vídeo encontrado.");
        }
      } catch (error) {
        console.error("Erro ao buscar vídeos:", error);
      }
    };

    fetchVideos();
  }, []);

  const handleChange = (e: any) => {
    setNewVideo({ ...newVideo, [e.target.name]: e.target.value });
  };

  const handleAddVideo = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!newVideo.url || !newVideo.name || !newVideo.classVideo) {
      alert('Todos os campos são obrigatórios');
      return;
    }

    try {
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        console.error("Token não encontrado.");
        return;
      }

      const response = await axios.post(
        "https://api-protmar-educacional.vercel.app/postvideosurl",
        newVideo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        setVideos([...videos, response.data.video]);
        setNewVideo({ url: "", name: "", classVideo: "" });
        alert("Vídeo adicionado com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao adicionar vídeo:", error);
    }
  };

  async function handleDelete(id: string) {
    try {
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        console.error("Token não encontrado.");
        return;
      }

      const response = await axios.delete(`https://api-protmar-educacional.vercel.app/deletevideourl/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setVideos(videos.filter((video) => video._id !== id));
        alert("Vídeo excluído com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao excluir vídeo:", error);
    }
  }

  return (
    <main id="adminMain">
      <Menu />
      <div>
        <div className="text-center mb-5">
          <h1 id="adminTitle" className="fw-bold">
            Tela de Administrador
          </h1>
        </div>

        {/* Formulário de Adicionar Vídeo */}
        <div className="mb-4">
          <h2>Adicionar Novo Vídeo</h2>
          {error && <div className="alert alert-danger">{error}</div>}
          <form onSubmit={handleAddVideo}>
            <div className="mb-3">
              <label htmlFor="url" className="form-label">
                URL do Vídeo
              </label>
              <input
                type="url"
                className="form-control"
                id="url"
                name="url"
                value={newVideo.url}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Nome do Vídeo
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={newVideo.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="classVideo" className="form-label">
                Classe do Vídeo
              </label>
              <select
                className="form-select"
                id="classVideo"
                name="classVideo"
                value={newVideo.classVideo}
                onChange={handleChange}
              >
                <option value="">Selecione a classe</option>
                <option value="integracao">Integração</option>
              </select>
            </div>
            <button type="submit" className="btn btn-primary">
              Adicionar Vídeo
            </button>
          </form>
        </div>

        {/* Tabela com os vídeos */}
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>NOME</th>
              <th>URL</th>
              <th>CLASSE VIDEO</th>
              <th>AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {videos.map((video) => (
              <tr key={video._id}>
                <td>{video._id}</td>
                <td>{video.name}</td>
                <td>
                  <a href={video.url} target="_blank" rel="noopener noreferrer">
                    {video.url}
                  </a>
                </td>
                <td>{video.classVideo}</td>
                <td>
                  <button
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      if (window.confirm("Tem certeza que deseja excluir este item?")) {
                        handleDelete(video._id);
                      }
                    }}
                    className="btn btn-danger"
                  >
                    Excluir
                  </button>

                  <Link to={'/admin/questoes/' + video._id} state={video} className="btn btn-primary">Questões</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
}
