import { useState } from 'react';
import './App.css';
// Importando o Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Estilos do Bootstrap
import 'bootstrap-icons/font/bootstrap-icons.css'; // Ícones do Bootstrap, se necessário
import { Menu } from '../menuCompont/Menu';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

function App() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  async function makeLogin() {
    if (login === '' || password === '') {
      alert("Usuário e senha são obrigatórios!");
      return;
    }
    try {
      const response = await axios.post("https://api-protmar-educacional.vercel.app/loginuser", {
        login,
        senha: password
      });
  
      if (response.status === 200) {
        // Salvar o token no local storage
        const { token } = response.data.user;
        sessionStorage.setItem('authToken', token);
        sessionStorage.setItem('user', JSON.stringify(response.data.user));
  
        // Redireciona em caso de sucesso
        navigate("/integracao");
      }
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      alert("Login falhou! Verifique suas credenciais.");
    }
  }
  

  const telaLogin = (
    <div id="AppMainContainerLogin" className="container mt-5">
      <h2 className="text-center mb-4">Protmar Educacional</h2>
      <div id="AppMainContainerInputs" className="d-flex flex-column align-items-center">
        <input
          onChange={(e) => setLogin(e.target.value)}
          type="text"
          className="form-control mb-3"
          placeholder="Usuário"
          value={login}
        />
        <input
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          className="form-control mb-3"
          placeholder="Senha"
          value={password}
        />
        <button onClick={makeLogin} className="btn btn-primary w-100">
          Login
        </button>
      </div>
    </div>
  );

  return (
    <main id="AppMain" className="d-flex justify-content-center align-items-center vh-100">
      <div id="AppMainContainerForm" className="border p-4 rounded shadow-sm">
        {telaLogin}
      </div>
    </main>
  );
}

export default App;
